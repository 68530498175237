.formulaire {
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  align-self: center;
  flex-direction: column;
  background: #f7f8f8;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.formulaire h1 {
  margin-bottom: 8px;
}

iframe {
  width: 100%;
  height: 850px;
  border: none;
  margin: 0;
  padding: 20px;
}

.call-me-button {
  height: 50px;
  min-width: 210px;
  border: none;
  border-radius: 10px;
  background: #282c35;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 7px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}
.call-me-button img {
  border: 2px solid white;
  border-radius: 100%;
  padding: 8px;
}

@media screen and (min-width: 640px) {
  .formulaire {
    margin: 0 auto;
    width: 80%;
  }

  iframe {
    width: 90%;
    height: 875px;
  }
}
