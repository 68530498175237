.banner {
  display: none;
}

.banner img {
  height: auto;
  width: 100%;
  border-radius: 7px;
}

@media screen and (min-width: 640px) {
  .banner {
    display: block;
    margin-top: 10px;
    width: 100%;
  }
}
