.header {
  background: #282c34;
  width: 100%;
  height: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: azure;
}

.header img {
  height: 100%;
  margin: 0 auto;
  border-radius: 5px;
}

.header a {
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 640px) {
  .header {
    background: #282c34;
    width: 100%;
    height: 9vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: azure;
  }
}
