*,
::before,
::after {
  box-sizing: border-box;
}

body {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  max-width: 1900px;
  position: relative;
}

@media screen and (min-width: 640px) {
  body {
    height: 100vh;
    margin: 0 auto;
  }
}
