.speech {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px 10px;
  height: auto;
}

.speech p {
  font-size: 18px;
  text-align: justify;
}

.alinea {
  text-indent: 20px;
}

@media screen and (min-width: 640px) {
  .speech p {
    font-size: 23px;
    text-align: center;
    width: 80%;
    margin: 8px 0;
  }
}
